<template>
	<v-container class="pt-0" fluid>
		<div class="text-h5">帮助中心</div>
		<div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

		<v-row no-gutters>
			<v-col class="pr-3" cols="6" offset="3">
				<v-text-field
						v-model="search.name"
						dense
						label="输入文件名称查询"
						outlined
				></v-text-field>
			</v-col>
		</v-row>
		<v-simple-table>
			<thead>
			<tr>
				<th class="text-left">#</th>
				<th class="text-left">文件名称</th>
				<th class="text-left">操作</th>
			</tr>
			</thead>
			<tbody>
			<tr
					v-for="(item, index) in table_data"
					:key="index"
					:style="index % 2 === 0 ? 'background: rgb(242,242,242)' : ''"
			>
				<td>{{ item.index }}</td>
				<td>{{ item.name }}</td>
				<td>
					<v-btn color="primary" small>下载</v-btn>
				</td>
			</tr>
			</tbody>
		</v-simple-table>
	</v-container>
</template>
<script>
// import { getProjectList } from "@/api/projects";
// import paginations from "@/components/paginations";

export default {
	name: "projects",
	mounted() {
		// this.doGet();
	},
	methods: {
		// details(id) {
		// 	this.$router.push({ name: "project_details", params: { id } });
		// },
		// doGet(page = this.search.page) {
		// 	this.search.page = page;
		// 	getProjectList(this.search).then((res) => {
		// 		this.table_data = res.results;
		// 		this.total = res.count;
		// 	});
		// },
		// changeSearch(e) {
		// 	this.search.name = e;
		// },
	},
	data: () => ({
		total: 0,
		dialog: false,
		search: {
			name: "",
			page: 1,
			pagesize: 10,
		},
		table_data: [
			{
				index: 1,
				name: "《健康建筑可持续评价技术规范》V1.0",

			},
			{
				index: 2,
				name: "《项目申报申明》模版",
			},
			{
				index: 3,
				name: "《项目协议书》模版",
			},
		],
	}),
	components: {
		// paginations,
	},
};
</script>
<style lang="stylus" scoped></style>
